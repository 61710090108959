<template>
	<div id="ShowMap">
		<div id="container" style="width:100%;height:400px;"></div>
	</div>
</template>

<script>
	export default {
		name: "ShowMap",
		components: {},
		data() {
			return {
				markersArray: [],
				restaurants: [],
				map: null,
				getAddress: null,
				getAddCode: null,
				addressKeyword: '',
				shopInfo: {
					lng: '',
					lat: '',
					addr: ''
				},
				isShow: false
			}
		},
		props: {
			location: {
				type: Array,
				default () {
					return []
				}
			}
		},

		mounted() {
			this.init()
		},
		methods: {
			// 初始化地图
			init() {
				var that = this
				// 定义地图中心点坐标
				var center = new window.TMap.LatLng(this.location[0].latitude, this.location[0].longitude)
				// 定义map变量，调用 TMap.Map() 构造函数创建地图
				that.map = new window.TMap.Map(document.getElementById('container'), {
					center: center, // 设置地图中心点坐标
					zoom: 13, // 设置地图缩放级别
					pitch: 0, // 设置俯仰角
					rotation: 0 // 设置地图旋转角度
				})

				this.location.forEach((item, index) => {
					let markerLayer = new window.TMap.MultiMarker({
						map: that.map,
						styles: {
							// 点标记样式
							marker: new window.TMap.MarkerStyle({
								width: 20, // 样式宽
								height: 30, // 样式高
								anchor: {
									x: 10,
									y: 30
								}, // 描点位置
								src: 'https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png' // 标记路径
							})
						}
					})
					markerLayer.add({

						id: that.shopInfo.lat,
						position: new window.TMap.LatLng(item.latitude, item.longitude),
						content: index === 0 ? "骑手" : "用户"
					})
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.serachinput {
		width: 300px;
		box-sizing: border-box;
		padding: 9px;
		border: 1px solid #dddee1;
		line-height: 20px;
		font-size: 16px;
		height: 38px;
		color: #333;
		position: relative;
		border-radius: 4px;
		-webkit-box-shadow: #666 0px 0px 10px;
		-moz-box-shadow: #666 0px 0px 10px;
		box-shadow: #666 0px 0px 10px;
	}

	::v-deep .el-dialog__header {
		border-bottom: 0 !important;
	}
</style>
